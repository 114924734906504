@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
@font-face {
	font-family: Cinzel-Regular;
	src: url(../../Util/fonts/Cinzel/Cinzel-Regular.ttf);
}
/*? SECTION 1 EMPEÑO MUNDO BARTER */
.EmpeñaSection1MB {
	background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Empe%C3%B1os/BODEGON_HOT.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
    display: flex;
}
.EmpeñaSection1MB  .body{
display: flex;
justify-content: center;
align-items: end;
gap: 20px;
}
.EmpeñaSection1MB .body .contentSection2MB{
width: 60%;
}
.EmpeñaSection1MB .body .contentSection1MB{
width: 40%;
display: flex;
flex-direction: column;
justify-content: center;
margin-bottom: 90px;
margin-left: 60px;
}
.EmpeñaSection1MB .body .contentSection1MB .titlesection2MB{
	color: #757d65;
	font-size: 80px;
	line-height: 80px;
	margin: 0 !important;
	font-weight: 400;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
}
.EmpeñaSection1MB .body .contentSection1MB .subtitlesection2MB{
    line-height: 35px;
	font-size: 35px;
	font-weight: 400;
    color: #371F13;
	font-family: Baskervville-Regular !important;
    width: 90%;
}
.EmpeñaSection1MB .body .contentSection1MB .subtitlesection2MB .bottomLink{
    display: flex;
}
.EmpeñaSection1MB .body .contentSection1MB  .bottomLink .btnLink{
    color: #757d65;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	text-transform: none;
	gap: 5px;
	border-radius: 100px;
}
.EmpeñaSection1MB .body .contentSection1MB  .bottomLink .btnLink:hover{
    background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {

      .EmpeñaSection1MB {
        height: 400px;
    }
  
    .EmpeñaSection1MB .body .contentSection2MB{
    width: 50%;
    }
    .EmpeñaSection1MB .body .contentSection1MB{
    width: 54%;    
    margin-bottom: 0px;
    margin-left: 10px;
    }
    .EmpeñaSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 26px;
        font-size: 26px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
    }
  
    
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
      .EmpeñaSection1MB {
        height: 550px;
    }
  
    .EmpeñaSection1MB .body .contentSection2MB{
    width: 50%;
    }
    .EmpeñaSection1MB .body .contentSection1MB{
    width: 54%;    
    margin-bottom: 10px;
    margin-left: 20px;
    }
    .EmpeñaSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 26px;
        font-size: 26px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
    }
       
}
@media (max-width: 600px) {
    .EmpeñaSection1MB {
        background-position: left;
        height: 289px;
    }
    .EmpeñaSection1MB  .body{
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 20px;
    }
    .EmpeñaSection1MB .body .contentSection2MB{
    width: 40%;
    }
    .EmpeñaSection1MB .body .contentSection1MB{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 10px;
    }
    .EmpeñaSection1MB .body .contentSection1MB .titlesection2MB{
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }
    .EmpeñaSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        margin: 9px 0px;
        width: 100%;
    }
    .EmpeñaSection1MB .body .contentSection1MB  .bottomLink .btnLink{
        font-size: unset;
        border-radius: 4px;
        background-color: #CED1C5;
    }
}

/*? SECTION 2 EMPEÑO MUNDO BARTER */
.EmpeñaSection2MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.EmpeñaSection2MB .cardESection2{
    width: 1300px;
}
.EmpeñaSection2MB .cardESection2 p{
    font-weight: 400;
    font-size: 50px;
    line-height: 72px;
   color:#BD7439;
   font-family: Baskervville-Regular !important;
   margin: 0;
}
.EmpeñaSection2MB .cardESection2 .ContainerInstructions{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 165px;
   margin-bottom: 200px;
}
.EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs{
    width: 15%;
}
.EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step{
    display: flex;
    flex-direction: column;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;

}
.EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .number{
    font-size: 350px;
    line-height: 420px;
    color: rgba(225, 194, 144, 1);
    font-weight: 400;
    font-family: Cinzel-Regular !important;
    position: absolute;
    opacity: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .text{
    color: #000;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    font-family: Baskervville-Regular !important;
    position: absolute;
    top: 66px;
    left: 27%;
    text-align: initial;
    width: 140px;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .EmpeñaSection2MB {
      padding:  0px 10px;
    }
    .EmpeñaSection2MB .cardESection2{
        width: 100%;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions{
     
        margin-bottom: 100px;
       
     }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs{
        width: 100%;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step{
        width: 100%;
    
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .number{
        font-size: 200px;
        line-height: 240px;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .text{
        font-size: 16px;
        line-height: 16px;
        top: 27px;
        left: 20%;
    }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .EmpeñaSection2MB {
        padding:  0px 10px;
      }
      .EmpeñaSection2MB .cardESection2{
          width: 100%;
      }
      .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs{
        width: 100%;
    }
      .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step{
          width: 100%;
      
      }
}
@media (max-width: 600px) {
    .EmpeñaSection2MB {
        padding:  0px 10px;
      }
    .EmpeñaSection2MB .cardESection2{
        width: 100%;
    }
    .EmpeñaSection2MB .cardESection2 p{
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions{
       align-items: start;
       width: 100%;
       height: 360px;
       margin-bottom: 0px;
       flex-wrap: wrap;
      
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs{
        width: 50%;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step{
        width: 100%;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .number{
        font-size: 200px;
        line-height: 240px;
    }
    .EmpeñaSection2MB .cardESection2 .ContainerInstructions .css-1c2fuzs .step .text{
        font-size: 16px;
        line-height: 16px;
        top: 27px;
        left: 20%;
    }
}

/*? SECTION 3 EMPEÑO MUNDO BARTER */
.EmpeñaSection3MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 125px;
}
.EmpeñaSection3MB .cardESection3{
    width: 1300px;
    display: flex;
   align-items: center;
    flex-direction: column;
    gap: 50px;
}
.EmpeñaSection3MB .cardESection3 .Logo{
    width: 30%;
}
.EmpeñaSection3MB .cardESection3 .text{
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Baskervville-Regular !important;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative{
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign{
  display: flex;
  gap: 60px;
}

.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design{
    height: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design .containerS1{
    background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Empe%C3%B1os/DSC06963.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 200px !important;
    width: 100%;
}

.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design .containerS2{
    background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Empe%C3%B1os/DSC08892.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 360px !important;
    width: 100%;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section2Design{
    height: auto;
    width: 40%;
    display: flex;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section2Design .containerS2{
    background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Empe%C3%B1os/DSC08026.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100% !important;
    width: 100%;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter{
    background-color: #fff;
    border-radius: 4px;
    width: 372px;
    height: 212px;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter{
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .textcardcenter{
   font-weight: 400;
   font-size: 20px;
   line-height: 24px;
   font-family: Rosario-Regular !important;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .bottomLink{
    display: flex;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .bottomLink .btnLink{
    color: #757d65;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	text-transform: none;
	gap: 5px;
	border-radius: 100px;
}
.EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .bottomLink .btnLink:hover{
    background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .EmpeñaSection3MB{
        /* margin-top: 0px; */
        padding: 0px 10px;
    }
    .EmpeñaSection3MB .cardESection3{
        width: 100%;
        /* gap: 20px; */
    }
   
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
     .EmpeñaSection3MB{
        /* margin-top: 0px; */
        padding: 0px 10px;
    }
    .EmpeñaSection3MB .cardESection3{
        width: 100%;
        /* gap: 20px; */
    }
}
@media (max-width: 600px) {
    .EmpeñaSection3MB{
        margin-top: 0px;
        padding: 0px 10px;
    }
    .EmpeñaSection3MB .cardESection3{
        width: 100%;
        gap: 20px;
    }
    .EmpeñaSection3MB .cardESection3 .Logo{
        width: 50%;
    }
    .EmpeñaSection3MB .cardESection3 .text{
        font-size: 16px;
        line-height: 19.2px;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative{
      width: 100%;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign{
      gap: 20px;
    }
    
    .EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design{
        width: 50%;
        gap: 10px;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design .containerS1{
        height: 170px !important;
    }
    
    .EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section1Design .containerS2{
        height: 170px !important;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .ContentDesign .section2Design{
        width: 50%;
    }
   
    .EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter{
        width: 190px;
        height: 140px;
        top: 44%;
        left: 50%;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter{
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .textcardcenter{
     
       font-size: 12px;
       line-height: 14.4px;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .bottomLink{
        display: flex;
    }
    .EmpeñaSection3MB .cardESection3 .Contentrelative .CardCenter .contentCardCenter .bottomLink .btnLink{
        font-size: unset;
        border-radius: 4px;
        background-color: #757d6563;
    }
}