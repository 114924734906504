.box-header-profile {
	width: 80%;
	/* height:450px; */
	height: 418px;
	position: relative;
	border-radius: 10px;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.header-profile1 {
	width: 100%;
	height: 200px;
	background: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
		linear-gradient(
			to top,
			rgba(255, 255, 255, 0.4) 0%,
			rgba(0, 0, 0, 0.25) 200%
		);
	background-blend-mode: multiply;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.avatar-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: rgb(0, 0, 0);
	border: solid 10px rgb(255, 255, 255);
	position: absolute;
	top: 100px;
	left: 50px;
}
.letter-avatar {
	color: white;
	font-size: 90px;
	font-weight: bold;
}
.header-profile2 {
	/* width: 100%; */
	width: auto;
	/* height:250px; */
	height: 137px;
	display: flex;
	line-height: 0px;
	flex-direction: column;
	align-items: flex-start;
	/* justify-content:flex-end; */
	justify-content: center;
	/* padding: 10px 10px 50px 50px; */
	padding: 0px 10px 0px 50px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-top: 83px;
}
.title-profile {
	font-size: 30px;
	font-weight: bold;
	text-transform: uppercase;
}
.btn-edit-profile {
	padding: 5px 20px !important;
	background-color: #c58989 !important;
	color: #ffffff !important;
	font-weight: bold !important;
	margin-bottom: 10px !important;
}
.box-profile-inf {
	margin: 30px 0px;
	/* padding: 5px 20px 20px 20px ; */
	padding: 0px 0px 0px 0px;
	border-radius: 10px;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.box-profile-inf-body {
	padding: 5px 20px 20px 20px;
}
.box-profile-table-info {
	margin: 30px 0px;
	padding: 0;
	border-radius: 10px;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.title-profile-inf {
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
}
.btn-save-edit {
	padding: 5px 20px !important;
	background-color: #9eb5ae !important;
	color: #fff !important;
	font-weight: bold !important;
	margin-bottom: 10px !important;
}
/* background: linear-gradient(45deg, #C58989, #CBB878,#9EB5AE, #D7D1C4) !important; */
.btn-table-Contratos {
	background-color: #9eb5ae !important;

	border: 1px solid #9eb5ae !important;
	color: #fff !important;
	font-weight: 600 !important;
	/* border: 1px solid #545454!important;
    color: #545454!important; */
}
.HeaderBoxProfile {
	text-align: center;
	background-color: #d7d1c4;
	border-radius: 10px 10px 0px 0px;
}
/*? MODAL DESTALLES DE CONTRATOS */
.backdropfilter {
	backdrop-filter: blur(10px) !important;
}
.container-modal-FacturasDetail {
	background-color: rgb(255, 255, 255);
	width: 800px;
	height: 590px;
	margin: auto;
	border-radius: 10px;
}
.container-modal-pdf {
	background-color: rgb(255, 255, 255);
	width: 780px;
	height: 950px;
	margin: auto;
	border-radius: 10px;
}
.header-modal-purchase {
	background-color: #d7d1c4;
	/*color: #000;
	*/
	height: 60px;
	display: flex;
	padding: 0px 20px 0px 20px;
	align-items: center;
	border-radius: 10px 10px 0px 0px;
}
.text-TitleDacturasDetail {
	font-weight: 600;
	color: '#545454';
	text-transform: uppercase;
}
.container-body-modal {
	/* padding: 10px 10px 0px 10px; */
	padding: 0px 0px 0px 0px;
	text-align: center;
}
.NohayContratos {
	font-size: 20px;
	color: #000;
	font-weight: 600;
}
.Container-Profile-Body {
	width: 80%;
}
.btn-table-Contratos-Download {
	background-color: #c58989 !important;
	color: #fff !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	font-weight: 600 !important;

	/* border: 1px solid #545454!important;
    color: #545454!important; */
}
.container-Checkbox-Profile {
	background-color: #00000009;
	border-radius: 7px 7px 7px 7px;
	margin-top: 1rem;
	padding: 10px 20px 10px 20px;
	display: flex;
	align-items: center;
}
/* background: linear-gradient(45deg, #C58989, #CBB878,#9EB5AE, #D7D1C4) !important; */
@media (max-width: 600px) {
	.box-header-profile {
		width: 95%;
		height: auto !important;
	}
	.avatar-profile {
		width: 150px;
		height: 150px;
		top: 122px;
		left: 28%;
	}
	.header-profile2 {
		width: 100%;
		height: 137px;
		display: flex;
		padding: 0px 10px 0px 0px;
		line-height: 0px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 60px;
	}
	.title-profile {
		font-size: 25px;
	}
	.Container-Profile-Body {
		width: 95% !important;
	}
	.btn-table-Contratos {
		font-size: 10px !important;
	}
	.container-modal-FacturasDetail {
		width: 95%;
		height: auto;
	}
	.btn-edit-profile {
		padding: 3px 11px !important;
		font-size: 10px !important;
	}
	.btn-save-edit {
		padding: 3px 11px !important;
		font-size: 10px !important;
	}
	.title-profile-inf {
		font-size: 17px;
	}
	.container-modal-pdf {
		background-color: rgb(255, 255, 255);
		width: 100%;
		height: 950px;
		margin: auto;
		border-radius: 10px;
	}
}
