.ContainerTerms {
	padding: 0 15%;
	text-align: justify;
}
.Container-Content-Terms {
	position: relative;
	padding: 2% 5%;
	margin-bottom: 30px;
}
.Container-Content-Terms::before {
	content: '';
	/* background-image: url(https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png); */
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.1;
	position: absolute;
	background-size: 7%;
}
.pos-relative{
	position: relative;
}
.Title {
	color: #727272;
	text-transform: uppercase;
	font-family: Helvetica;
	margin-bottom: 0;
}
.Subtitle {
	text-transform: uppercase;
	font-family: Helvetica;
	font-weight: bold;
	margin-top: 10px;
	font-size: 25px;
}

.section-Title {
	font-weight: bold;
}
.section-Body {
	color: #727272;
}
.section-Body a {
	position: relative;;
}
.italic {
	font-style: italic!important;
}
.subrayado {
	text-decoration: underline!important;
}
@media (max-width: 600px) {
	.ContainerTerms {
		padding: 0 2%;
	}
	.Container-Content-Terms {
		padding: 2% 4%;
	}
	.Title {
		font-size: 25px;
	}
	.Subtitle {
		font-size: 20px !important;
	}
}
