/*? ********************************* VENTAS ********************************* */
.ContainerCardSectionHomeVentas1 {
  display: flex;
}
.ContainerCardSectionHomeVentas1 .SectionIMG {
  background-image: url("../../Images/MB-Consigna/RenuevaCloset.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeVentas1 .SectionInformation {
  background-color: #d7d1c4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 30px;
}
.ContainerCardSectionHomeVentas1 .title {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.ContainerCardSectionHomeVentas1 .subtitle {
  color: #000;
  text-align: center;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeVentas1 {
    flex-direction: column;
  }
  .ContainerCardSectionHomeVentas1 .SectionInformation {
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeVentas1 .SectionIMG {
    height: 250px;
  }
}
.ContainerCardSectionHomeVentas2 {
  display: flex;
}
.ContainerCardSectionHomeVentas2 .SectionIMG {
  background-image: url("../../Images/MB-Consigna/ConsignaSL.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeVentas2 .SectionInformation {
  background-color: #c58989;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 40px 30px;
  height: 400px;
}
.ContainerCardSectionHomeVentas2 .title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}
.ContainerCardSectionHomeVentas2 .subtitle {
  color: #fff;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeVentas2 {
    flex-direction: column-reverse;
  }
  .ContainerCardSectionHomeVentas2 .SectionInformation {
    align-items: center;
    width: 100%;
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeVentas2 .SectionIMG {
    height: 250px;
  }
  .ContainerCardSectionHomeVentas2 .title {
    font-size: 30px;
    text-align: center;
  }
  .ContainerCardSectionHomeVentas2 .subtitle {
    color: #fff;
    text-align: center;
    padding: 0 10px;
  }
}
/*? ********************************* EMPEÑOS ********************************* */
.ContainerCardSectionHomeEmpeños1 {
  display: flex;
}
.ContainerCardSectionHomeEmpeños1 .SectionIMG {
  background-image: url("../../Images/MB-Empena/Prestamos.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeEmpeños1 .SectionInformation {
  background-color: #d7d1c4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 30px;
}
.ContainerCardSectionHomeEmpeños1 .title {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.ContainerCardSectionHomeEmpeños1 .subtitle {
  color: #000;
  text-align: center;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeEmpeños1 {
    flex-direction: column;
  }
  .ContainerCardSectionHomeEmpeños1 .SectionInformation {
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeEmpeños1 .SectionIMG {
    height: 250px;
  }

  .ContainerCardSectionHomeEmpeños1 .subtitle {
    padding: 0 10px;
  }
}
.ContainerCardSectionHomeEmpeños2 {
  display: flex;
}
.ContainerCardSectionHomeEmpeños2 .SectionIMG {
  background-image: url("../../Images/MB-Empena/Empena.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeEmpeños2 .SectionInformation {
  background-color: #c58989;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 40px 30px;
  height: 400px;
}
.ContainerCardSectionHomeEmpeños2 .title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}
.ContainerCardSectionHomeEmpeños2 .subtitle {
  color: #fff;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeEmpeños2 {
    flex-direction: column-reverse;
  }
  .ContainerCardSectionHomeEmpeños2 .SectionInformation {
    align-items: center;
    width: 100%;
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeEmpeños2 .SectionIMG {
    height: 250px;
  }
  .ContainerCardSectionHomeEmpeños2 .title {
    font-size: 30px;
    text-align: center;
  }
  .ContainerCardSectionHomeEmpeños2 .subtitle {
    color: #fff;
    text-align: center;
    padding: 0 10px;
  }
}
/*? ********************************* APARTADOS ********************************* */
.ContainerCardSectionHomeApartados1 {
  display: flex;
}
.ContainerCardSectionHomeApartados1 .SectionIMG {
  background-image: url("../../Images/MB-Aparta/img-apartado.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeApartados1 .SectionInformation {
  background-color: #d7d1c4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 30px;
}
.ContainerCardSectionHomeApartados1 .title {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.ContainerCardSectionHomeApartados1 .subtitle {
  color: #000;
  text-align: center;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeApartados1 {
    flex-direction: column;
  }
  .ContainerCardSectionHomeApartados1 .SectionInformation {
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeApartados1 .SectionIMG {
    height: 250px;
  }

  .ContainerCardSectionHomeApartados1 .subtitle {
    padding: 0 10px;
  }
}
.ContainerCardSectionHomeApartados2 {
  display: flex;
}
.ContainerCardSectionHomeApartados2 .SectionIMG {
  background-image: url("../../Images/MB-Aparta/img-apartado2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ContainerCardSectionHomeApartados2 .SectionInformation {
  background-color: #c58989;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 40px 30px;
  height: 400px;
}
.ContainerCardSectionHomeApartados2 .title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}
.ContainerCardSectionHomeApartados2 .subtitle {
  color: #fff;
}

@media (max-width: 600px) {
  .ContainerCardSectionHomeApartados2 {
    flex-direction: column-reverse;
  }
  .ContainerCardSectionHomeApartados2 .SectionInformation {
    align-items: center;
    width: 100%;
    padding: 40px 0px;
  }
  .ContainerCardSectionHomeApartados2 .SectionIMG {
    height: 250px;
  }
  .ContainerCardSectionHomeApartados2 .title {
    font-size: 30px;
    text-align: center;
  }
  .ContainerCardSectionHomeApartados2 .subtitle {
    color: #fff;
    text-align: center;
    padding: 0 10px;
  }
}
